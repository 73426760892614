import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import { Link } from "gatsby";

const PageNotFound404 = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Sidan hittades inte" }} />
      <main>
        <section id="page-not-found" class="ud-pt-[120px] ud-pb-20">
          <div className="ud-container">
            <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
              <div className="ud-w-full ud-px-4">
                <div
                  className="wow fadeInUp ud-mx-auto ud-max-w-[570px] ud-text-center"
                  data-wow-delay=".2s"
                >
                  {" "}
                  <h3
                    class="
                  ud-mb-4 ud-text-3xl
                  ud-font-bold
                  ud-text-black
                  dark:ud-text-white
                  sm:ud-text-4xl
                "
                  >
                    Sidan hittades inte
                  </h3>
                  <p
                    class="
                  ud-mb-10 ud-text-base ud-font-medium
                  ud-leading-relaxed
                  ud-text-body-color
                  sm:ud-text-lg
                  sm:ud-leading-relaxed
                "
                  >
                    Sidan du letade efter verkar ha flyttat, blivit borttagen
                    eller inte existerat.
                  </p>
                  <Link
                    className="
                    ud-mx-2
                    ud-rounded-full
                    ud-bg-primary
                    ud-py-4
                    ud-px-8
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-transition
                    ud-duration-300
                    ud-ease-in-out
                    hover:ud-bg-opacity-80
                    "
                    to={`/`}
                  >
                    Gå till startsidan
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PageNotFound404;
